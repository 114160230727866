<template>
	<div>
			<el-container :style="defaultHeight">
				<el-aside width="auto" class="asideMain">
					<left-menu></left-menu>
				</el-aside>
				<el-main >
					<el-header>
						<nav-header></nav-header>
					</el-header>
					<router-view></router-view>
				</el-main>
			</el-container>
	</div>
</template>

<script>
	import NavHeader from './NavHeader';
	import LeftMenu from './LeftMenu';
	export default {
		data() {
			return {
				containerHeight: {
					height:""
				},
				defaultHeight: {
				    height: ""
				},
			}
		},
		components: {
			NavHeader,
			LeftMenu,
		},
		methods: {
			getHeight() {
			     this.defaultHeight.height = window.innerHeight + "px";
			}
		},
		mounted() {
			//页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
			window.addEventListener("resize", this.getHeight);
			this.getHeight();
		}
	}
</script>

<style lang="less" scoped="scoped">
	html {
		width: 100%;
		height: 100%;
	}
	body {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		.el-main {
			padding: 0;
		}

		.el-header {
			padding: 0;
		}

		.el-menu {
			border: 0;
		}
	}
	.el-aside {
		z-index: 999;
		display: block;
		position: relative;
		overflow-y: scroll;
		overflow-y:hidden;
	}
	.el-menu:not(.el-menu--collapse) {
	      width: auto;
	}
</style>
