<template>
  <div class="left">
	<el-menu 
		:default-openeds="openeds"
		:default-active="activeMenu" 
		collapse-transition
		class="el-menu-vertical-demo" 
		:collapse="isCollapse"
		background-color="#545c64"
		text-color="#fff"
		active-text-color="#ffd04b"
		router>
		<div class="logo"><img src="../assets/img/zlbf_logo.png" alt="智岚博丰"></div>
	  <el-menu-item index="/home/index" @click.native="showRouter">
	    <i class="el-icon-menu" style="color: #FFFFFF;"></i>
	    <span slot="title">概况总览</span>
	  </el-menu-item>
	  <el-menu-item index="/server/server" @click.native="showRouter">
	    <i class="el-icon-monitor" style="color: #FFFFFF;"></i>
	    <span slot="title">业务中心</span>
	  </el-menu-item>
	  <el-menu-item index="/distribution/distribution" @click.native="showRouter">
	    <i class="el-icon-goods" style="color: #FFFFFF;"></i>
	    <span slot="title">运营</span>
	  </el-menu-item>
	  <el-menu-item index="/asset/asset" @click.native="showRouter">
	    <i class="el-icon-goods" style="color: #FFFFFF;"></i>
	    <span slot="title">数字通证</span>
	  </el-menu-item>
	  <el-submenu index="2" >
	    <template slot="title">
	      <i class="el-icon-user" style="color: #FFFFFF;"></i>
	      <span>个人中心</span>
	    </template>
	    <el-menu-item-group>
	      <el-menu-item index="/personal/info" @click.native="showRouter">基本信息</el-menu-item>
	      <el-menu-item index="/personal/authentication" @click.native="showRouter">实名认证</el-menu-item>
	      <el-menu-item index="/personal/password" @click.native="showRouter">修改密码</el-menu-item>
	    </el-menu-item-group>
	  </el-submenu>
	  <!-- <el-menu-item index="/ecological/package" v-if="base.market != 2" @click.native="showRouter">
	    <i class="el-icon-refresh" style="color: #FFFFFF;"></i>
	    <span slot="title">生态板块</span>
	  </el-menu-item> -->
	 <!-- <el-menu-item index="/distribution/distribution" v-if="base.agent > 1" @click.native="showRouter">
	    <i class="el-icon-trophy-1" style="color: #FFFFFF;"></i>
	    <span slot="title">分销中心</span>
	  </el-menu-item> -->
	  <!-- <el-menu-item index="/announcement/announcement" @click.native="showRouter1" style="display: none;">
	    <i class="el-icon-postcard" style="color: #FFFFFF;"></i>
	    <span slot="title">系统公告</span>
	  </el-menu-item> -->
	</el-menu>
  </div>

</template>

<script>
	import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
	//刷新
	name: 'NavBar',
	// 接收注入的数据
	inject: [
		'reload'
	],
data() {
	return {
		 openeds: ['2'],
	};
},
//刷新
 methods: {
		    showRouter () {
		      // 调用reload方法，刷新整个页面
		      this.reload()
		    },
			showRouter1 () {
			  // 调用reload方法，刷新整个页面
			  this.$cookieStore.setCookie( 'username' ,'name');
			  this.reload()
			},
		  },
  computed:{
	  ...mapState('menu', ['isCollapse']),
    base(){
      return this.$store.getters.getBase
    },
	 activeMenu() {
	      const route = this.$route
	      const { meta, path } = route
	      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
	      if (meta.activeMenu) {
	        return meta.activeMenu
	      }
	      return path
	    }
	
  }
}
</script>

<style lang="less">
body,
html {
	width: 100%;
	height: 100%;
}
.left {
    .logo {
      background-color: #545c64;
      text-align: center;
      width: 211px;
      padding: 20px 0;
    }
}
.el-menu-item-group__title {
	padding: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
.el-menu-vertical-demo {
		overflow: hidden;
		height: calc(190vh - 90px);
	}
.el-aside {
	 overflow: hidden;
}
</style>
