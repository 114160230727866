<template>
  <div class="bg-nav">
		<i  :class="{'el-icon-s-fold':!isCollapse,'el-icon-s-unfold':isCollapse}" @click="toggleMenu" id="switch"></i>
    <div class="user-role">
      <!-- <span v-show="base.agent_name != null">{{base.agent_name}}</span> -->
      <span style="margin: 0 10px">{{base.username}}</span>
	  <!-- <span style="font-size: 18px;" v-if="base.next_agent != ''">({{base.next_agent}})</span> -->
    </div>
    <div class="user-operation">
      <span class="exit" @click="handleEdit" style="cursor: pointer;">安全退出</span>
    </div>
    <div class="block avatar">
		<img src="../assets/img/avatar.png"></img>
	</div>
  </div>
</template>

<script>
import { Admin } from "@/api/index";
import { mapState,mapMutations } from 'vuex';
  export default {
	  inject: [
	  	'reload'
	  ],
    data() {
      return {
		  
      };
    },
	created () {
		
	},
    methods: {
		...mapMutations('menu',['toggleMenu']),
    async handleEdit() {
				let { status, data } = await Admin.logout();
				if (status) {
					if(data.code == 0) {
						localStorage.clear()
						let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
						if (keys) {
							for (var i = keys.length; i--;) {
								document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
								document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
								document.cookie = keys[i] + '=0;path=/;domain=aleopool.top;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
							}
						}
						this.$router.replace('/login')
						this.$message.success('退出成功')
					}else {
						this.$message.error(data.message)
					}
				}
			},
    },
    computed:{
		...mapState('menu',['isCollapse']),
      base(){
        return this.$store.getters.getBase
      },
    }
  }
</script>

<style lang="less">
	body {
		margin: 0;
		padding: 0;
		.el-header {
			padding: 0 0 0 30px;
		}
	}
	.old {
		position: absolute;
		right: 300px;
	}
	.bg-nav {
		display: flex;
		align-items: center;
		background-color: #ffffff;
		color: #333333;
		height: 60px;
		position: relative;
    .user-role {
      padding-left: 20px;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      vertical-align: middle;
    }
		.el-icon-s-fold,.el-icon-s-unfold {
      color: #1989FA;
      font-size: 20px;
      vertical-align: middle;
      margin-left: 30px;
	  cursor: pointer;
    }
    .user-operation {
		cursor: pointer;
      display: flex;
      vertical-align: middle;
      position: absolute;
      right: 100px;
	  bottom: 18px\9;
      .el-badge {
        margin-right: 20px;
      }
      .exit {
        // border-left: 1px solid #999999;
        padding-left: 20px;
      }
    }
    .avatar {
      vertical-align: middle;
      position: absolute;
      right: 30px;
	  bottom: 0;
    }
}
</style>